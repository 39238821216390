import BoldExtension, { BoldOptions } from "@tiptap/extension-bold";
import BulletListExtension from "@tiptap/extension-bullet-list";
import DocumentExtension from "@tiptap/extension-document";
import HardBreakExtension from "@tiptap/extension-hard-break";
import HistoryExtension, { HistoryOptions } from "@tiptap/extension-history";
import ItalicExtension from "@tiptap/extension-italic";
import LinkExtension from "@tiptap/extension-link";
import ListItemExtension from "@tiptap/extension-list-item";
import OrderedListExtension from "@tiptap/extension-ordered-list";
import ParagraphExtension from "@tiptap/extension-paragraph";
import PlaceholderExtension, {
  PlaceholderOptions,
} from "@tiptap/extension-placeholder";
import TextExtension from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";
import { css, StyleSheet } from "aphrodite";
import { useMemo } from "react";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import clsx from "clsx";
import { SuggestionOptions } from "@tiptap/suggestion";
import { Mention } from "@tiptap/extension-mention";
import { Node, Mark, Extension } from "@tiptap/core";
import HorizontalRuleExtension from "@tiptap/extension-horizontal-rule";
import "katex/dist/katex.min.css";
import {
  Mathematics,
  MathematicsOptions,
} from "@tiptap-pro/extension-mathematics";

type Params = {
  placeholder: string;
  listIndentation?: LIST_INDENTATION_LEVELS;
  enableMentions: boolean;
  mentionsConfig?: Omit<SuggestionOptions, "editor">;
};

type RichTextEditorExtensions = (
  | Node
  | Mark<BoldOptions>
  | Extension<HistoryOptions>
  | Extension<PlaceholderOptions>
  | Extension<MathematicsOptions>
)[];

const useRichTextEditorExtensions = (
  args: Params,
): RichTextEditorExtensions => {
  const { enableMentions, placeholder, mentionsConfig, listIndentation } = args;

  return useMemo(() => {
    const extensions = [
      TextExtension,
      ParagraphExtension,
      DocumentExtension,
      BoldExtension,
      ItalicExtension,
      HardBreakExtension,
      HorizontalRuleExtension,
      Image.configure({
        inline: true,
      }),
      Mathematics,
      Heading.configure({
        levels: [1, 2, 3, 4],
      }),
      Table.configure({
        HTMLAttributes: {
          class: css(styles.richTextTable),
        },
      }),
      TableRow.configure(),
      TableHeader.configure(),
      TableCell.configure({
        HTMLAttributes: {
          class: css(styles.richTextTableInner),
        },
      }),
      OrderedListExtension.configure({
        HTMLAttributes: {
          class: clsx(
            "list-decimal",
            listIndentation && LIST_INDENTATION_TO_CLASS_NAME[listIndentation],
          ),
        },
      }),
      ListItemExtension.configure({
        HTMLAttributes: {
          class: listIndentation
            ? LIST_INDENTATION_TO_CLASS_NAME[listIndentation]
            : undefined,
        },
      }),
      BulletListExtension.configure({
        HTMLAttributes: {
          class: "list-disc",
        },
      }),
      HistoryExtension,
      LinkExtension.extend({ inclusive: true }).configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "rich-text-link",
        },
      }),
      PlaceholderExtension.configure({
        placeholder,
      }),
    ];
    if (enableMentions) {
      extensions.push(
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion: mentionsConfig,
        }),
      );
    }
    return extensions;
  }, [enableMentions, listIndentation, mentionsConfig, placeholder]);
};

export type LIST_INDENTATION_LEVELS = 8 | 16 | 24;

export const LIST_INDENTATION_TO_CLASS_NAME: Record<
  LIST_INDENTATION_LEVELS,
  string
> = {
  8: "list-indentation-8",
  16: "list-indentation-16",
  24: "list-indentation-24",
};

const styles = StyleSheet.create({
  richTextTable: {
    border: `2px solid ${deprecatedTones.gray5Alpha}`,
    borderCollapse: "collapse",
    borderRadius: 12,
    width: "100%",
  },
  richTextTableInner: {
    border: `2px solid ${deprecatedTones.gray5Alpha}`,
    wordBreak: "break-word",
    minWidth: 60,
    minHeight: 60,
    padding: 4,
    fontSize: "14px",
    lineHeight: "18px",
  },
});

export default useRichTextEditorExtensions;
